<template>
  <div class="pb-5" id="audit-list-item">
    <h2>Сверка {{ auditListItem.DateStart }} - {{ auditListItem.DateEnd }}</h2>
    <!--    <div class="alert alert-primary d-print-none" role="alert">-->
    <!--      <p>-->
    <!--        Уважаемый партнер.-->
    <!--      </p>-->

    <!--      <p>-->
    <!--        Для более оперативного и удобного проведения сверок предлагаем ознакомиться с новым сервисом в вашем личном кабинете.-->
    <!--      </p>-->

    <!--      <p>Теперь 1-го числа каждого месяца доступна (активна) ссылка, где предоставлена информация об изготовленных заказах за предыдущий отчетный период.</p>-->

    <!--      <p>Обращаем ваше внимание, что стоимость заказа указана вместе со стоимостью макетов, если таковые были.</p>-->

    <!--      <p>Вашим специалистам необходимо проверить соответствие цены и в случае отсутствия разногласий, утвердить - поставить “галку” напротив заказа, либо - оставить комментарий.</p>-->

    <!--      <p>После проверки сверки - нажмите “Сверено”. В случае возникновения спорных ситуаций, с вами свяжется наш сотрудник. Подтвержденная сверка будет считаться согласованной и являться основанием для выставления счета.</p>-->

    <!--      <p>Счет будет выставлен после согласования разногласий.</p>-->
    <!--    </div>-->
    <div class="alert alert-primary d-print-none" role="alert">
      <p>Уважаемый партнер.</p>
      <p>
        Сверка формируется 1-го числа каждого месяца, здесь предоставлена
        информация об изготовленных заказах за предыдущий отчетный период.
      </p>
      <p>Обращаем ваше внимание:</p>
      <ul>
        <li>
          заказы добавляются по дате их фактической готовности (что может быть
          на день или два раньше даты отправки),
        </li>
        <li>
          стоимость заказа указана вместе со стоимостью макетов, если таковые
          были,
        </li>
        <li>
          удержания за макеты, в случае отказа от заказа показаны “датой
          готовности” заказа от которого отказались (если от заказа готовность
          которого была назначена на 08.09 - клиент отказался, то удержание за
          выполненные макеты будет в базе от 08.09)
        </li>
      </ul>
      <p>Вашему специалисту необходимо проверить соответствие цены.</p>
      <p>
        <span class="fw-bold">заказ утверждён</span> - если “галка” установлена
        - <span class="fw-bold">(комментарий не учитывается!!!)</span>
      </p>
      <p>
        <span class="fw-bold">заказ не сверен</span> - пишете комментарий -
        <span class="fw-bold">(не нужно ставить “галку”)</span>
      </p>
      <p>
        После проверки сверки - нажмите “Сверено”. В случае возникновения
        спорных ситуаций, с вами свяжется наш сотрудник. Подтвержденная сверка
        будет считаться согласованной и являться основанием для выставления
        счета.
      </p>
      <p>Счет будет выставлен после согласования разногласий.</p>
    </div>
    <div class="my-4 text-end">
      <button v-print="'#printMe'" class="btn btn-warning me-3">Печать</button>
      <a
        class="btn btn-info"
        :href="
          'https://api.bn.tables.sferazakaz.ru/audit/customer-audit-item/' +
          $route.params.id +
          '/download'
        "
        target="_blank"
        >Сохранить</a
      >
    </div>
    <table class="table table-bordered mt-3" id="printMe">
      <thead>
        <tr>
          <th>#</th>
          <th>Дата<br />оформления</th>
          <th>Дата<br />отправки</th>
          <th>Номер закза</th>
          <th>Партнер: {{ point.Reception }}</th>
          <th>Цена</th>
          <th>
            <input
              type="checkbox"
              :checked="allChecked"
              @change="toggleAllIsChecked"
            />
          </th>
          <th class="d-print-none"></th>
        </tr>
      </thead>
      <tbody
        v-for="(auditItem, index) in auditItems"
        :key="index"
        :class="{
          'bg-danger': auditItem.AuditDescription && !auditItem.IsApproved,
          'bg-success': auditItem.IsApproved,
        }"
      >
        <tr>
          <td>{{ 1 + index }}</td>
          <td class="text-nowrap">{{ auditItem.ReceivedDate }}</td>
          <td class="text-nowrap">{{ auditItem.DispatchDate }}</td>
          <td>
            {{ auditItem.SferaNumber }}
          </td>
          <td
            style="font-size: 0.9rem; line-height: 1rem; vertical-align: middle"
          >
            {{ auditItem.SferaNumber }}
            {{ auditItem.LocalNumber ? " / " + auditItem.LocalNumber : "" }}
            {{ auditItem.Name }}
          </td>
          <td>{{ auditItem.Summary }}</td>
          <td>
            <input
              type="checkbox"
              :checked="auditItem.IsApproved"
              @change="toggleApproved(auditItem, $event)"
            />
          </td>
          <td class="d-print-none">
            <a href="#" @click.prevent="toggleAuditComment(auditItem)"
              >Комментарий</a
            >
          </td>
        </tr>
        <tr
          v-if="
            !auditItem.IsApproved &&
            (showComment[auditItem.Id] === true || auditItem.AuditDescription)
          "
        >
          <td></td>
          <td colspan="7">
            <input
              type="text"
              class="form-control"
              v-model="auditItem.AuditDescription"
              @keyup="commitComment(auditItem)"
            />
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr class="fw-bold">
          <td colspan="5" class="text-end">Итого</td>
          <td>{{ TotalSummary }}</td>
          <td></td>
          <td class="d-print-none"></td>
        </tr>
      </tbody>
    </table>
    <div class="mb-5 mt-4 text-center">
      <button
        type="button"
        class="btn"
        :class="{
          'btn-success': auditListItem.CustomerApproved,
          'btn-outline-primary': !auditListItem.CustomerApproved,
        }"
        style="min-width: 200px"
        @click.prevent="toggleCustomerApproved"
      >
        {{
          auditListItem.CustomerApproved
            ? "Сверка отправлена. Спасибо!"
            : "Сверено"
        }}
      </button>
    </div>
  </div>
</template>
<script>
import api from "../../../services/api";
import _ from "lodash";
import makeAuthUrl from "../../../services/qs";

export default {
  data() {
    return {
      auditListItem: {},
      auditItems: [],
      showComment: {},
      point: {},
    };
  },
  async mounted() {
    await this.fetchListItem();
  },
  computed: {
    allChecked() {
      return !_.some(this.auditItems, { IsApproved: false });
    },
    TotalSummary() {
      if (this.auditItems) {
        return _.sumBy(this.auditItems, "Summary");
      } else {
        return 0;
      }
    },
  },
  methods: {
    async fetchListItem() {
      const url = makeAuthUrl(
        `/audit/customer-audit-list-list/${this.$route.params.id}`,
        this.$route.query.umi_id,
        this.$route.query.key
      );
      const { data } = await api.get(url);
      this.auditListItem = data.auditListItem;
      this.auditItems = data.auditItems;
      this.point = data.point;
    },
    toggleAuditComment(auditItem) {
      if (!this.showComment[auditItem.Id]) {
        this.$set(this.showComment, auditItem.Id, true);
      } else {
        this.$set(this.showComment, auditItem.Id, false);
      }
    },
    commitComment: _.debounce(async function (auditItem) {
      // console.log(auditItem.AuditDescription);
      const url = makeAuthUrl(
        `/audit/customer-audit-item/${auditItem.Id}/update-comment`,
        this.$route.query.umi_id,
        this.$route.query.key
      );
      await api.post(url, {
        AuditDescription: auditItem.AuditDescription,
      });
    }, 500),
    toggleApproved: _.debounce(async function (auditItem) {
      auditItem.IsApproved = !auditItem.IsApproved;
      const url = makeAuthUrl(
        `/audit/customer-audit-item/${auditItem.Id}/update-is-approved`,
        this.$route.query.umi_id,
        this.$route.query.key
      );
      await api.post(url, {
        IsApproved: auditItem.IsApproved,
      });
    }, 100),

    toggleAllIsChecked: _.debounce(async function () {
      const auditItemsIds = _.map(this.auditItems, (item) => {
        return item.Id;
      });
      const url = makeAuthUrl(
        `/audit/customer-audit-item/collection/update-is-approved`,
        this.$route.query.umi_id,
        this.$route.query.key
      );
      await api.post(url, {
        ids: auditItemsIds,
        value: !this.allChecked,
      });
      await this.fetchListItem();
    }, 100),

    toggleCustomerApproved: _.debounce(async function () {
      const url = makeAuthUrl(
        `/audit/customer-audit-item/${this.auditListItem.Id}/toggle-customer-approved`,
        this.$route.query.umi_id,
        this.$route.query.key
      );
      await api.get(url);
      await this.fetchListItem();
    }, 100),
  },
};
</script>
<style lang="scss">
#audit-list-item {
  table {
    tbody.bg-success {
      tr {
        background-color: #a1f5b4 !important;

        td {
          border-color: #69c169 !important;
        }
      }
    }

    tbody.bg-danger {
      tr {
        background-color: #ffb9c0 !important;
        border-color: #c38e94 !important;

        td {
          border-color: #c38e94 !important;
        }
      }
    }
  }
}
</style>
